/*desktop*/
@media (min-width: 769px){
    .footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 4rem;
        background-color: var(--bluemenu);
    }
    .footer_brand{
        width: 15%;
    
    }
    .footer_img{
        width: 100%;
    }
    .footer_txtContainer{
        width: 70%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .footer_quote{
        width: 100%;
    }
    .footer_contactDate{
        width: 24%;
    }
    .footer_copy{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer_contactTitle{
        font: var(--basictitle);
        color: var(--textcolor);
    }
    .footer_contactText{
        font: var(--text);
        color: var(--textcolor);
    }
}
/*Mobile*/
@media (max-width: 768px){
    .footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 4rem;
        padding-top: 3rem;
        background-color: var(--bluemenu);
    }
    .footer_brand{
        width: 50%;
    
    }
    .footer_img{
        width: 100%;
    }
    .footer_txtContainer{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .footer_quote{
        width: 100%;
    }
    .footer_contactDate{
        width: 100%;
    }
    .footer_contactTitle{
        font: var(--basictitle);
        color: var(--textcolor);
    }
    .footer_contactText{
        font: var(--text);
        color: var(--textcolor);
    }
    .footer_copy{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}