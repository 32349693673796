/*desktop*/
@media (min-width: 769px){
    .navContainer{
        width: 100%;
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 2rem;
        z-index: 20;
        background-color: var(--bluemenualfa);
    }
    .nav_header{
        width: 50%;
        padding: 0rem 3rem 0rem 3rem;
    }
    .nav_dinamicMenu{
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 2rem;
    }
    
}

/*Mobile*/
@media (max-width: 768px){
    .navContainer{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        z-index: 20;
    }
    .nav_header{
        width: 100%;
        height: 20vh;
        display: flex;
        flex-direction: row;
        column-gap: 3rem;
        align-items: center;
        z-index: 10;
        
    }
    .nav_dinamicMenu{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 4rem;
        background-color: var(--bluemenu);
    }
  /*animation for nav section*/
    .--close{
        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }
    @keyframes slide-out-top {
        0% {
          transform: translateY(0);
          height: 80vh;
          opacity: 1;
        }
        100% {
          transform: translateY(-1000px);
          opacity: 0;
          height: 0vh;
        }
      }
    .--open{
        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-in-top {
        0% {
          transform: translateY(-1000px);
          opacity: 0;
          height: 0vh;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          height: 80vh;
        }
      }

    /*animation for header*/
    .--disabled{
        animation: opacity-out 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }
    @keyframes opacity-out {
        0% {
          background-color: var(--bluemenu);
        }
        100% {
          background-color: var(--bluemenualfa);
        }
      }
    .--enabled{
        animation: opacity-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes opacity-in {
        0% {
          background-color:  var(--bluemenualfa);
        }
        100% {
          background-color: var(--bluemenu);
        }
      }

}