/*Desktop*/
@media (min-width: 769px){
    .heroContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .hero_h1{
        padding: 2rem;
        font: var(--maintitle)
    }
    .hero-btnContainer{
        display: flex;
        align-items: row;
        column-gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}


/*Mobile*/
@media (max-width: 768px){
    .heroContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .hero_h1{
        font: var(--maintitlemobile);
    }
    .hero-btnContainer{
        display: flex;
        align-items: row;
        column-gap: 1rem;
        justify-content: center;
        align-items: center;
    }   
}
/*General*/
.hero_h1{
    color: var(--textcolor);
    text-align: center;
}
    /*general mod's*/
    .--paralax{
        /* The image used */
        background-image: url('https://i.imgur.com/Q4ixtSc.png');

        /* Full height */
        width: 100%;
        
        
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
    }






