/*Desktop*/
@media (min-width: 769px){
    .home{
        width: 100%;
        height: auto;
        
    }
}
/*Mobile*/
@media (max-width: 768px){
    .home{
        width: 100%;
        height: auto;
        
    }
}