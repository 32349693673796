/*desktop*/
@media (min-width: 769px){
  .nav_icon{
    display: none;
  }
}

/*Mobile*/
@media (max-width: 768px){
    .nav_icon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 3rem;
        font-size: 3rem;
        border: 0.016rem solid var(--textcolor);
        color: var(--textcolor);
    }
    
/*Animacion Button*/
    .--up{
        animation: rotate-180-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes rotate-180-cw {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(180deg);
        }
      }
      .--down{
        animation: rotate-0-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      @keyframes rotate-0-cw {
        0% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(0);
        }
      }
      .--down{
        animation: rotate-0-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      @keyframes rotate-0-cw {
        0% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(0);
        }
      }
/*BG Animacion Button*/
     .--checked{
      animation: bg-color 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      @keyframes bg-color {
        0% {
          background-color: rgba(255, 255, 255, 0);
        }
        100% {
          background-color: rgba(255, 255, 255, 0.301);
          
        }
      }
      .--unchecked{
        animation: bg-color-out 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
        @keyframes bg-color-out {
          0% {
            background-color: rgba(255, 255, 255, 0.301);
          }
          100% {
            background-color: rgba(255, 255, 255, 0);
            
          }
        }
}

