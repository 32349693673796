/*Desktop*/
@media (min-width: 769px) {
  .form_p {
    font: var(--maintitle);
    color: var(--textcolor);
  }

  .contactFrm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 2rem;
    background-color: gray;
    background-image: url(https://i.imgur.com/Vwx9GJR.png);
    background-size: cover;
  }
  .form_form {
    width: 60%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0rem;
    row-gap: 2rem;
    padding-bottom: 3rem;
  }

  .form_lt {
    width: 20%;
    font: var(--formtext);
    color: var(--textcolor);
  }
  .form_ltInput {
    width: 100%;
    height: 2rem;
    border: 1px solid #fff;
    background-color: transparent;
  }
  .form_ltInput:focus {
    background-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
  .form_lg {
    width: 100%;
    font: var(--formtext);
    color: var(--textcolor);
  }
  .form_lgInput {
    width: 100%;
    height: 5%;
    border: 1px solid #fff;
    background-color: transparent;
  }
  .form_lgInput:focus {
    background-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
  .form_btn {
    width: 100%;
  }

  .form_btnInput {
    width: 100%;
    height: 3rem;
    border: 1px solid #fff;
    background-color: transparent;
    font: var(--formtext);
    color: var(--textcolor);
    transition: 0.5s ease-in-out;
  }
  .form_btnInput:hover {
    background-color: #ffffff56;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .form_btnInput:active {
    background-color: #ffffff8e;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
/*Mobile*/
@media (max-width: 768px){
  .form_p {
    font: var(--maintitlemobile);
    color: var(--textcolor);
  }

  .contactFrm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 2rem;
    background-color: gray;
    background-image: url(https://i.imgur.com/Vwx9GJR.png);
    background-size: cover;
  }
  .form_form {
    width: 60%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 0rem;
    row-gap: 2rem;
    padding-bottom: 3rem;
  }

  .form_lt {
    width: 100%;
    font: var(--formtext);
    color: var(--textcolor);
  }
  .form_ltInput {
    width: 100%;
    height: 2rem;
    border: 1px solid #fff;
    background-color: transparent;
  }
  .form_ltInput:focus {
    background-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
  .form_lg {
    width: 100%;
    font: var(--formtext);
    color: var(--textcolor);
  }
  .form_lgInput {
    width: 100%;
    height: 5%;
    border: 1px solid #fff;
    background-color: transparent;
  }
  .form_lgInput:focus {
    background-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
  .form_btn {
    width: 100%;
  }

  .form_btnInput {
    width: 100%;
    height: 3rem;
    border: 1px solid #fff;
    background-color: transparent;
    font: var(--formtext);
    color: var(--textcolor);
    transition: 0.5s ease-in-out;
  }
  .form_btnInput:hover {
    background-color: #ffffff56;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .form_btnInput:active {
    background-color: #ffffff8e;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
