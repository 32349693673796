
html{
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}
.App{
    width: 100%;
}
:root{
    --bluemenu: #222c2b;
    --bluemenualfa: #222c2bbd;
    --hovernav: #8FC1B5;
    --graybg: #3e3e3e; 
    --textcolor: #ffffff;
    --texcoloralfa: #ffffff57;
    --maintitlemobile: 400 2.2rem/normal Playfair Display;
    --maintitle: 400 4rem/normal Playfair Display; 
    --text: 300 1.25rem/normal Fira Sans; 
    --subtitle: 400 2.5rem/normal Playfair Display; 
    --formtext: 400 1.25rem/normal Playfair Display; 
    --strongtext: 500 1.5rem/normal Fira Sans; 
    --basictitle: 400 2.25rem/normal Playfair Display; 
}
