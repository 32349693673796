.bodyBtn{
    font: var(--text);
    color: var(--textcolor)
}
.--t1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.5rem;
    height: 0.5rem;
    padding: 1rem;
    background-color: var(--bluemenualfa);
    border: 0.016rem solid var(--textcolor);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    transition: 0.5s ease-in-out;
    
}
.--t1:hover{
    background-color: var(--texcoloralfa);
}
.--t2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.5rem;
    height: 0.5rem;
    padding: 1rem;
    border: 0.016rem solid var(--textcolor);
    background-color: var(--bluemenualfa);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    transition: 0.5s ease-in-out;
}
.--t2:hover{
    border-radius: 1rem;
    background-color: var(--bluemenualfa);
}
.--t3{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2rem;
    border: 0.016rem solid var(--textcolor);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    transition: 0.5s ease-in-out;
     
}
.--t3:hover{
    background-color: var(--texcoloralfa);
}